<template>
  <navbar>
    <div class="navbar-brand" @click="$router.go(-1)">
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.6666 9.66667V12.3333H5.66663L13 19.6667L11.1066 21.56L0.546631 11L11.1066 0.440002L13 2.33334L5.66663 9.66667H21.6666Z"
          fill="#F2F2F2"
        />
      </svg>
    </div>
    <h6 class="title-navbar">
      Profil
    </h6>
  </navbar>

  <div class="profile__detail-wrapper">
    <form @submit.prevent="handleSubmitUpdateProfile">
      <div class="mb-3">
        <label for="firstname" class="form-label">Nama Depan</label>
        <input
          type="text"
          class="form-control form__group-profile"
          v-model="accountDetail.firstName"
          placeholder="Nama Depan"
        />
      </div>
      <div class="mb-3">
        <label for="lastname" class="form-label">Nama Belakang</label>
        <input
          type="text"
          class="form-control form__group-profile"
          v-model="accountDetail.lastName"
          placeholder="Nama Belakang"
        />
      </div>
      <div class="mb-3">
        <label for="lastname" class="form-label">Nomor Ponsel</label>
        <input
          type="text"
          class="form-control form__group-profile"
          v-model="accountDetail.phoneNumber"
          placeholder="Nomor Ponsel"
        />
      </div>
      <div class="mb-3">
        <label for="address" class="form-label">Alamat</label>
        <textarea
          class="form-control"
          placeholder="Alamat"
          id="floatingTextarea2"
          style="height: 100px"
          v-model="accountDetail.address"
        ></textarea>
      </div>
      <div class="mb-3">
        <label for="dob" class="form-label">Tanggal Lahir</label>
        <div v-if="accountDetail.dob !== null" class="d-flex align-items-center">
          <input type="text" class="form-control mr-1" :value="accountDetail.dob" readonly/> <i class="fas fa-trash" style="cursor:pointer;" @click="handleRemoveDob"></i>
        </div>
        <input v-else type="date" class="form-control" v-model="accountDetail.dob" />
      </div>
      <div class="group__donation-wrapper">
        <button type="submit" class="btn btn-warning btn-pay" :disabled="isDisableSaveProfile">
          Simpan Perubahan
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import swal from 'sweetalert2'

import Navbar from '../../components/TransactionComponent/NavbarTransaction.vue'

export default {
  name: 'Profile',
  components: { Navbar },
  data() {
    return {
      accessToken: null,
      accountDetail: {
        userId: null,
        email: null,
        firstName: null,
        lastName: null,
        phoneNumber: null,
        address: null,
        dob: null
      }
    }
  },
  computed: {
    isDisableSaveProfile() {
      if (
        this.accountDetail.firstName === null ||
        this.accountDetail.firstName === '' ||
        this.accountDetail.lastName === null ||
        this.accountDetail.lastName === '' ||
        this.accountDetail.phoneNumber === null ||
        this.accountDetail.phoneNumber === ''
      ) {
        return true
      }

      return false
    }
  },
  mounted() {
    this.isAccessToken()
    this.getAccountDetail()
  },
  methods: {
    isAccessToken() {
      if (process.browser) {
        if (localStorage.getItem('access_token')) {
          this.accessToken = localStorage.getItem('access_token')
        }
      }
    },
    getAccountDetail() {
      swal.fire({
        title: '', // add html attribute if you want or remove
        text: 'Mohon Menunggu',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: function() {
          swal.showLoading()
        }
      })
      this.$http
        .get(`${process.env.VUE_APP_API_URL}me`, {
          headers: {
            Authorization: `Bearer ${this.accessToken}`
          }
        })
        .then(response => {
          const data = response.data
          this.accountDetail = {
            userId: data.user_id,
            firstName: data.detail.first_name,
            email: data.email,
            lastName: data.detail.last_name,
            phoneNumber: data.detail.phone_number,
            address: data.detail.address,
            dob: this.$moment(data.detail.dob).format('DD/MM/YYYY')
          }
          swal.close()
          swal.hideLoading()
        })
    },
    handleSubmitUpdateProfile() {
      swal.fire({
        title: '', // add html attribute if you want or remove
        text: 'Mohon Menunggu',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: function() {
          swal.showLoading()
        }
      })
      this.$http
        .post(
          `${process.env.VUE_APP_API_URL}me/profile`,
          {
            // eslint-disable-next-line @typescript-eslint/camelcase
            first_name: this.accountDetail.firstName,
            // eslint-disable-next-line @typescript-eslint/camelcase
            last_name: this.accountDetail.lastName,
            email: this.accountDetail.email,
            // eslint-disable-next-line @typescript-eslint/camelcase
            phone_number: this.accountDetail.phoneNumber,
            address: this.accountDetail.address,
            dob: this.$moment(this.accountDetail.dob).format('YYYY-MM-DD')
          },
          {
            headers: {
              Authorization: `Bearer ${this.accessToken}`
            }
          }
        )
        .then(response => {
          swal.close()
          swal.hideLoading()
          if (response.data === 'Profile has changed') {
            swal.fire('Berhasil', 'Profil berhasil diperbaharui', 'success')
          }
        })
        .catch(error => {
          swal.close()
          swal.hideLoading()
        })
    },
    handleRemoveDob() {
      this.accountDetail.dob = null
    }
  }
}
</script>
